import React from 'react';

import Layout from '../../layout/CompanyLayout';
import PendingProjectListView from '../../views/company/PendingProjectListView';
const IndexPage = (): React.ReactElement => {
  return (
    <Layout>
      <PendingProjectListView></PendingProjectListView>
    </Layout>
  );
};

export default IndexPage;
