import React, { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  StyleRules,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import {
  // PassCompanyBiddingProjectDialog,
  RefuseCompanyBiddingProjectDialog,
} from '../../components/Dialog';

const styles = (): StyleRules =>
  createStyles({
    content: {
      padding: '20px',
      maxWidth: '600px',
    },
    border: {
      border: '1px solid #d9d9d9',
      padding: '20px',
      borderRadius: '3px',
    },
    buttonMargin: {
      margin: '10px',
    },
    marginTopUP: {
      margin: '20px 0',
    },
  });

const pendingProjectChangeView = ({ classes, onClose }: Props): ReactElement => {
  const [isRefuseBiddingOpen, setIsRefuseBiddingOpen] = useState(false);

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <>
      <Helmet title="申请详情"></Helmet>

      <Grid className={classes.content} container justifyContent="center">
        <Grid item xs={12} className={`${classes.border} ${classes.marginTopUP}`}>
          <Typography variant="h6">
            进行中项目不能直接更改项目信息，更改功能发送更改的需求请求给傅通，傅通收到更改请求24小时处理更改，提示（多选选择：1.修改内容，2.更换顾问，3.其他）
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                color="primary"
              />
            }
            label="修改内容"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedB}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="更换顾问"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedF}
                onChange={handleChange}
                name="checkedF"
                color="primary"
              />
            }
            label="其他"
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.buttonMargin}
            //   onClick={() => {
            //     setIsRefuseBiddingOpen(false);
            //   }}
          >
            确认提交
          </Button>
        </Grid>
      </Grid>
      <RefuseCompanyBiddingProjectDialog
        isOpen={isRefuseBiddingOpen}
        handleDialogClose={() => {
          setIsRefuseBiddingOpen(false);
          onClose ? onClose() : '';
        }}
        handleEdit={() => {
          setIsRefuseBiddingOpen(false);
          onClose ? onClose() : '';
        }}
      ></RefuseCompanyBiddingProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
}

export default withStyles(styles)(pendingProjectChangeView);
