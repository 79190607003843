import React, { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  createStyles,
  Grid,
  StyleRules,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import EditIcon from '@material-ui/icons/Edit';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonIcon from '@material-ui/icons/Person';

import {
  PassCompanyBiddingProjectDialog,
  RefuseCompanyBiddingProjectDialog,
} from '../../components/Dialog';

const styles = (): StyleRules =>
  createStyles({
    titleFont: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    boxSpace: {
      padding: '1rem',
      margin: '1rem',
    },
    buttonMargin: {
      margin: '1rem',
    },
    buttonFlex: {
      display: 'flex',
      justifyContent: 'center',
    },
    boxContent: {
      paddingLeft: '2rem',
    },
  });

const pendingProjectDetailsView = ({
  classes,
  onClose,
  onCommentOpen,
  editOpen,
  statusValue,
}: Props): ReactElement => {
  const [isPassBiddingOpen, setIsPassBiddingOpen] = useState(false);
  const [isRefuseBiddingOpen, setIsRefuseBiddingOpen] = useState(false);
  return (
    <>
      <Helmet title="申请详情"></Helmet>
      <Grid className={classes.content} container justifyContent="center">
        <Grid item xs={12} className={classes.boxSpace}>
          <Typography variant="h6" className={classes.titleFont}>
            <AssignmentIcon /> 项目信息
          </Typography>
          <div className={classes.boxContent}>
            <Typography>项目名称</Typography>
            <Typography>项目内容</Typography>
            <Typography>工作方式</Typography>
            <Typography>工作量</Typography>
            <Typography>工作地址</Typography>
            <Typography>项目时间</Typography>
            <Typography>项目预算</Typography>
            <Typography>联系方式</Typography>
          </div>
        </Grid>

        <Grid item xs={12} className={classes.boxSpace}>
          <Typography variant="h6" className={classes.titleFont}>
            <PersonIcon /> 项目顾问
          </Typography>
          <div className={classes.boxContent}>
            <Typography>姓名</Typography>
            <Typography>简介</Typography>
            <Typography>联系方式</Typography>
          </div>
        </Grid>

        <Grid item xs={12} className={classes.boxSpace}>
          <Typography variant="h6" className={classes.titleFont}>
            <ChatBubbleOutlineIcon /> 评价信息
          </Typography>
          <div className={classes.boxContent}>
            <Typography>顾问评价：xxx</Typography>
            <Typography>企业评价：xxx</Typography>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.boxSpace}>
          <div className={classes.buttonFlex}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.buttonMargin}
              onClick={() => {
                onCommentOpen ? onCommentOpen() : '';
              }}
            >
              <ChatBubbleOutlineIcon fontSize="small" /> 评价
            </Button>

            {statusValue == 0 && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.buttonMargin}
                onClick={() => {
                  // onClose ? onClose() : '';
                  editOpen ? editOpen() : '';
                }}
              >
                <EditIcon fontSize="small" /> 更改
              </Button>
            )}

            {statusValue != 2 && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.buttonMargin}
                //   onClick={() => {
                //     setIsRefuseBiddingOpen(true);
                //   }}
              >
                <LocalAtmIcon fontSize="small" /> 结算
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <PassCompanyBiddingProjectDialog
        isOpen={isPassBiddingOpen}
        handleDialogClose={() => {
          setIsPassBiddingOpen(false);
          onClose ? onClose() : '';
        }}
        handleEdit={() => {
          setIsPassBiddingOpen(false);
          onClose ? onClose() : '';
        }}
      ></PassCompanyBiddingProjectDialog>

      <RefuseCompanyBiddingProjectDialog
        isOpen={isRefuseBiddingOpen}
        handleDialogClose={() => {
          setIsRefuseBiddingOpen(false);
          onClose ? onClose() : '';
        }}
        handleEdit={() => {
          setIsRefuseBiddingOpen(false);
          onClose ? onClose() : '';
        }}
      ></RefuseCompanyBiddingProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  onEnterBiddingOpen?: Function;
  onCommentOpen?: Function;
  editOpen?: Function;
  statusValue?: string | number;
}

export default withStyles(styles)(pendingProjectDetailsView);
