import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  createStyles,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import PersonIcon from '@material-ui/icons/Person';

import { showNotificationAction } from '../../reducers/notificationReducer';

const styles = (): StyleRules =>
  createStyles({
    commentContent: {
      margin: '0 auto',
      padding: '50px',
    },
    commentButton: {
      margin: '1rem',
    },
    contentOver: {
      maxHeight: '500px',
      overflow: 'auto',
    },
    commentTime: {
      textAlign: 'right',
      fontSize: '0.875rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    itemAlign: {
      display: 'flex',
      alignItems: 'center',
      margin: '1rem 0',
    },
    spaceFront: {
      width: '1.5rem',
    },
    fontBol: {
      fontWeight: 'bold',
      padding: '0 0.3rem',
    },
  });

const PendingProjectComment = ({ classes, onClose }: Props) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');

  const handleCommentSubmit = async (): Promise<void> => {
    if (!comment || comment.trim() == '') {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '请输入内容',
        })
      );
      return;
    }
    onClose ? onClose() : '';
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value);
  };

  return (
    <div className={classes.commentContent}>
      <Typography variant="h5" className={classes.itemAlign} gutterBottom>
        <ChatBubbleOutlineIcon />
        <span className={classes.fontBol}> 评论</span>
      </Typography>
      <p className={classes.itemAlign}>
        <span className={classes.spaceFront}></span>
        <BusinessIcon></BusinessIcon> <span className={classes.fontBol}> 企业评价：</span>xxx
      </p>
      <p className={classes.itemAlign}>
        <span className={classes.spaceFront}></span>
        <PersonIcon></PersonIcon>
        <span className={classes.fontBol}> 顾问评价：</span> xxx
      </p>

      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        label="发布评论"
        value={comment}
        onChange={handleCommentChange}
      />
      <Button
        type="button"
        variant="contained"
        color="primary"
        className={classes.commentButton}
        onClick={handleCommentSubmit}
      >
        提交评论
      </Button>
      {/* <Button
        type="button"
        variant="contained"
        color="primary"
        className={classes.commentButton}
        onClick={handleCommentSubmit}
      >
        编辑评论
      </Button> */}
    </div>
  );
};

interface Props extends WithStyles<typeof styles> {
  className?: string;
  ConsultantId?: number;
  onClose?: Function;
}

export default withStyles(styles)(PendingProjectComment);
