export const formatDate: Function = (date: Date, format: string): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以加1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  if (!year) return '';

  switch (format) {
    case 'yyyy-MM-dd':
      return `${year}-${month}-${day}`;
    case 'HH:mm:ss':
      return `${hours}:${minutes}:${seconds}`;
    case 'yyyy-MM-dd HH:mm:ss':
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    default:
      throw new Error('Invalid format');
  }
};

// 使用示例
// const now = new Date();
// console.log(formatDate(now, 'yyyy-MM-dd')); // 输出类似 "2023-07-19"
// console.log(formatDate(now, 'HH:mm:ss')); // 输出类似 "14:30:00"
// console.log(formatDate(now, 'yyyy-MM-dd HH:mm:ss')); // 输出类似 "2023-07-19 14:30:00"
